import * as generateToChat from './generateToChat';

const onMessage = callback => {
  generateToChat.callbackEventWithTransformMessage(
    'ChatWidget.onMessageReceived',
    callback,
    'BUSINESS_TO_CUSTOMER',
  );
  generateToChat.callbackEventWithTransformMessage(
    'ChatWidget.onMessageSent',
    callback,
    'CUSTOMER_TO_BUSINESS',
  );
  return;
};

const isMembersChatEnabled = () =>
  generateToChat.requestWithResult(
    'ChatWidget.getChatSettings',
    {},
    'isSocialChat',
  );

const isBusinessChatEnabled = () =>
  generateToChat.requestWithResult(
    'ChatWidget.getChatSettings',
    {},
    'isBusinessChat',
  );

const isWidgetAvailable = () =>
  generateToChat.requestWithResult(
    'ChatWidget.getChatState',
    {},
    'isWidgetAvailable',
  );

const startPrivateChat = userId =>
  generateToChat.request('ChatWidget.startPrivateChat', {
    userId,
  });

export const chatApiInternal = {
  onMessage,
  isMembersChatEnabled,
  isBusinessChatEnabled,
  isWidgetAvailable,
  startPrivateChat,
};
